import PropTypes from 'prop-types'
import React from 'react'

const ListWithHeading = ({ heading, listElements }) => (
  <>
    <b>{heading}</b>
    <ul>
      {(listElements.map(name => (<li key={name}>{name}</li>)))}
    </ul>
  </>
)

ListWithHeading.propTypes = {
  heading: PropTypes.string,
  listElements: PropTypes.array,
}

ListWithHeading.defaultProps = {
  heading: '',
  listElements: [],
}

export default ListWithHeading
