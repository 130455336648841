import React from 'react'
import PropTypes from 'prop-types'

const PageWrapper = ({ children }) => (
  <div className="container">
    <div className="row justify-content-center">
      <div className="col align-self-center">
        <div className="jumbotron jumbotron-fluid jumb-page">
          {children}
        </div>
      </div>
    </div>
  </div>
)

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageWrapper
