import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/service-components/seo'
import PageWrapper from '../components/page-wrapper'
import ListWithHeading from '../components/list-with-heading'
import { translate } from 'react-i18next'

const About = ({ t }) => (
  <Layout>
    <SEO title="About" />
    <PageWrapper>
      <div className="container about-me">
        <div className="row">
          <article className="col-12">
            <h2 className="text-center">{t('about')}</h2>
            <p className="text-left">
              {t('about-header')}
            </p>
            <ListWithHeading heading={t('about-who-worked-with-title')}
              listElements={t('about-who-worked-with').split(';')} />
            <ListWithHeading heading={t('about-what-write-title')}
              listElements={t('about-what-write').split(';')} />
            <p className="text-left">
              {t('about-themes')}
            </p>
            <b>{t('about-how-will-work')}</b>
            <p className="text-left">
              {t('about-how-will-work-text')}
            </p>
          </article>
        </div>
      </div>
    </PageWrapper>
  </Layout>
)

export default translate('about')(About)
